import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Admin4d } from '../admin4d/admin4d';
import { Admin5a } from '../admin5a/admin5a';

@Component({
  selector: 'page-admin4e',
  templateUrl: 'admin4e.html'
})
export class Admin4e {

  constructor(public navCtrl: NavController) {
  }

  ionViewDidEnter() {
    console.log('admin 4e');
  }

  prevPage = Admin4d;
  nextPage = Admin5a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}