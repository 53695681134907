import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step2b2 } from '../step2b2/step2b2';
import { Step2c } from '../step2c/step2c';

@Component({
  selector: 'page-step2b3',
  templateUrl: 'step2b3.html'
})
export class Step2b3 {

  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
  console.log('2b3');

    this.toast = this.toastCtrl.create({
      message: "The green valve is shown here in the open position.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present()
    }, 1000);

    setTimeout(() => {
      this.start = true;
    }, 1000);
  }

  prevPage = Step2b2 ;
  nextPage = Step2c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
