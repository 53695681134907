import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Step2d } from '../step2d/step2d';
import { Admin } from '../admin/admin';

@Component({
  selector: 'page-step3a',
  templateUrl: 'step3a.html'
})
export class Step3a {
  
  pausePlay(event) {
    let video = event.target;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  constructor(public navCtrl: NavController) {
  }

  ionViewDidLoad() {
    console.log('3a');
  }

  ionViewWillLeave() {
  }

  prevPage = Step2d;
  nextPage = Admin;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
