import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step1c } from '../step1c/step1c';
import { Step2a } from '../step2a/step2a';

@Component({
  selector: 'page-step1d',
  templateUrl: 'step1d.html'
})
export class Step1d {

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {}

  start = false;
  toast: any;

  ionViewDidEnter() {
    console.log('1d');

    this.toast = this.toastCtrl.create({
      message: "If not already open, turn the green valve on the EzFill Dispenser to the open position.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present()
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  ionViewWillLeave() {
  }

  prevPage = Step1c ;
  nextPage = Step2a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
