import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step2a } from '../step2a/step2a';
import { Step2b2 } from '../step2b2/step2b2';

@Component({
  selector: 'page-step2b1',
  templateUrl: 'step2b1.html'
})
export class Step2b1 {

  start = false;
  toast: any;
  can = false;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
  console.log('2b1');
  
    this.toast = this.toastCtrl.create({
      message: "Remove the cap on the EzFill Can. Lift the Lever on the dispenser.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);

    setTimeout(() => {
    //  this.can = true;
    }, 3000);
  }


  toggleOK() {
    this.start = !this.start;

    if (this.start) {
      setTimeout(() => {
        this.can = true;
      }, 1000);
    } else {
      this.can = false;
    }
  }

  prevPage = Step2a ;
  nextPage = Step2b2;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
