import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { Admin2b } from '../admin2b/admin2b';
import { Admin3a } from '../admin3a/admin3a';

@Component({
  selector: 'page-admin2c',
  templateUrl: 'admin2c.html'
})
export class Admin2c {
  
  start = false;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 2c');

    let toast = this.toastCtrl.create({
      message: "Open the blue stopcock valve. \n\nAfter the capsule has separated, the pressure should drop below 7.0 kPa indicating that the Capsule has entered the stomach and dissolved.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    //  this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Admin2b;
  nextPage = Admin3a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
  	let alert = this.alertCtrl.create({
      message: 'CAUTION: Do not proceed with inflation until the Capsule has entered the stomach as confirmed by fluoroscopy/x-ray and the gauge reading is less than 7.0 kPa.',
      buttons: [{
	    text: 'Ok',
	    handler: () => {
	      // user has clicked the alert button
	      // begin the alert's dismiss transition
	      let navTransition = alert.dismiss();

	      navTransition.then(() => {
	        this.navCtrl.setRoot(page);
	      });
	
	      return false;
	    }
	  }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}