import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { Step1d } from '../step1d/step1d';
import { Step2b1 } from '../step2b1/step2b1';

@Component({
  selector: 'page-step2a',
  templateUrl: 'step2a.html'
})
export class Step2a {
  
  start = false;
  toast: any;
  On = false;
  Arrow = false;
  Message2 = false;

  gaugeOn() {
    this.On = !this.On;
/*
    if (!this.Message2) {
      this.toast.data.message = this.toast.data.message + "\n\nIf not '0.0' then hold the zero button for at least 2 seconds. \nNote: Do not press zero button unless the green valve is in the open position.";
      this.Message2 = !this.Message2;
    }
*/
  }

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }

  ionViewDidLoad() {
    console.log('2a');
  }

  toggleOK() {
    this.start = !this.start;
  }

  ionViewDidEnter() {
    this.toast = this.toastCtrl.create({
      message: "Press the power button on the dispenser’s digital gauge, and verify that the displayed value is in kPa and reads '0.0'.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.On = !this.On;
    });

    setTimeout(() => {
    //  this.toast.present();
    }, 3000);

    setTimeout(() => {
      this.start = true;
    }, 1000);

    setTimeout(() => {
      this.Arrow = !this.Arrow;
    }, 4000);
  }

  ionViewWillLeave() {
  }

  prevPage = Step1d ;
  nextPage = Step2b1;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
  	let alert = this.alertCtrl.create({
      message: 'CAUTION: Do not proceed with EzFill Dispenser setup until digital gauge reads 0.0 kPa.',
      buttons: [{
	    text: 'Ok',
	    handler: () => {
	      // user has clicked the alert button
	      // begin the alert's dismiss transition
	      let navTransition = alert.dismiss();

	      navTransition.then(() => {
	        this.navCtrl.setRoot(page);
	      });
	
	      return false;
	    }
	  }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}