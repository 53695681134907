import { Component } from '@angular/core';
import { NavController, LoadingController, AlertController } from 'ionic-angular';

import { GlobalVars } from '../../app/app.vars';

import { Index } from '../index/index';

@Component({
  selector: 'page-splash',
  templateUrl: 'splash.html'
})
export class Splash {
  
  constructor(public navCtrl: NavController, public loadingCtrl: LoadingController, private alertCtrl: AlertController, public globalVars: GlobalVars) {

    this.siteAltitude = 0;
    this.pressureLowerLimit = 11.5;
    this.pressureUpperLimit = 13.5;
    this.siteRange = 150;

    this.siteAltitude = Math.round(this.siteAltitude / 10) * 10;

    this.altitudeRange = ((this.siteAltitude <= this.siteRange) ? 0 : this.siteAltitude - this.siteRange)
                         + ' to ' + 
                        ((this.siteAltitude >= 8000 - this.siteRange) ? 8000 : this.siteAltitude + this.siteRange);

    this.pressureLimits = (135.5 + 2.5712 * this.pressureLowerLimit - 0.0044 * 
                          ((this.siteAltitude <= this.siteRange) ? 0 : this.siteAltitude - this.siteRange)).toFixed(1)
                          + ' to ' +
                          (135.5 + 2.5712 * this.pressureUpperLimit - 0.0044 *
                          ((this.siteAltitude >= 8000 - this.siteRange) ? 8000 : this.siteAltitude + this.siteRange)).toFixed(1);

    this.idealPressure = (135.5 + 2.5712 * 12.7 - 0.0044 * this.siteAltitude).toFixed(1);

    // not used
    this.pressureMin = (126.6554 + 3.212497 * this.pressureLowerLimit - 0.0045 * 
                       ((this.siteAltitude <= 300) ? 0 : this.siteAltitude - 300));

    this.pressureMax = (126.6554 + 3.212497 * this.pressureUpperLimit - 0.0045 *
                       ((this.siteAltitude >= 7700) ? 8000 : this.siteAltitude + 300));


    this.globalVars.setGlobalVar({
      siteAltitude: this.siteAltitude,
      altitudeRange: this.altitudeRange,
      pressureLimits: this.pressureLimits,
      idealPressure: this.idealPressure,
      pressureMin: this.pressureMin,
      pressureMax: this.pressureMax
    });
  }

  ionViewDidLoad() {
  }

  public siteAltitude: any;
  public pressureLowerLimit: any;
  public pressureUpperLimit: any;
  public altitudeRange: any;
  public pressureLimits: any;
  public idealPressure: any;
  public pressureMin: any;
  public pressureMax: any;
  public siteRange: any;

  
  presentCalculatorPrompt() {
    let alert = this.alertCtrl.create({
      title: 'Site Altitude',
      message: 'Please enter your current altitude?',
      cssClass: 'calculator',
      inputs: [
        {
          name: 'siteAltitude',
          placeholder: 'Site Altitude',
          type: 'number',
          value: ''// this.siteAltitude
        },
        /*
        {
          name: 'pressureLowerLimit',
          placeholder: 'Pressure Lower Limit',
          type: 'number',
          value: ''// this.pressureLowerLimit
        },
        {
          name: 'pressureUpperLimit',
          placeholder: 'Pressure Upper Limit',
          type: 'number',
          value: ''// this.pressureUpperLimit
        }
        */
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Set',
          handler: data => {

            // move to function

            this.siteAltitude = Math.round(data.siteAltitude / 10) * 10;

            this.altitudeRange = ((this.siteAltitude <= this.siteRange) ? 0 : this.siteAltitude - this.siteRange)
                         + ' to ' + 
                        ((this.siteAltitude >= 8000 - this.siteRange) ? 8000 : this.siteAltitude + this.siteRange);

            this.pressureLimits = (135.5 + 2.5712 * this.pressureLowerLimit - 0.0044 * 
                          ((this.siteAltitude <= this.siteRange) ? 0 : this.siteAltitude - this.siteRange)).toFixed(1)
                          + ' to ' +
                          (135.5 + 2.5712 * this.pressureUpperLimit - 0.0044 *
                          ((this.siteAltitude >= 8000 - this.siteRange) ? 8000 : this.siteAltitude + this.siteRange)).toFixed(1);


            this.idealPressure = (135.5 + 2.5712 * 12.7 - 0.0044 * this.siteAltitude).toFixed(1);


            this.pressureMin = (126.6554 + 3.212497 * this.pressureLowerLimit - 0.0045 * 
                       ((this.siteAltitude <= 300) ? 0 : this.siteAltitude - 300));

            this.pressureMax = (126.6554 + 3.212497 * this.pressureUpperLimit - 0.0045 *
                       ((this.siteAltitude >= 7700) ? 8000 : this.siteAltitude + 300));

            this.globalVars.setGlobalVar({
              siteAltitude: this.siteAltitude,
              altitudeRange: this.altitudeRange,
              pressureLimits: this.pressureLimits,
              idealPressure: this.idealPressure,
              pressureMin: this.pressureMin,
              pressureMax: this.pressureMax
            });

          }
        }
      ]
    });
    alert.present();
  }

  nextPage = Index;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
