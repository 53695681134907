import { Component } from '@angular/core';
import { NavController, NavParams } from 'ionic-angular';

@Component({
  selector: 'page-popover1',
  templateUrl: 'popover1.html'
})
export class Popover1 {

  label: any;

  constructor(public navCtrl: NavController, public navParams: NavParams) {
  	// for modal
    this.label = navParams.get('label');
  }

  ionViewDidLoad() {
  }

}
