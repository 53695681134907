import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin4a } from '../admin4a/admin4a';
import { Admin4c } from '../admin4c/admin4c';

@Component({
  selector: 'page-admin4b',
  templateUrl: 'admin4b.html'
})
export class Admin4b {
  
  start = false;
  closed = false;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 4b');

    let toast = this.toastCtrl.create({
      message: "Rotate the stopcock valve 90° to close the flow of gas from the Obalon EzFill Dispenser.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    //  this.start = true;
      setTimeout(() => {
    //    this.closed = 'Closed';
      }, 2000);
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
      setTimeout(() => {
    //    this.closed = 'Closed';
      }, 2000);
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
    this.closed = !this.closed;
  }

  prevPage = Admin4a;
  nextPage = Admin4c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  toggleValve() {
    this.start = !this.start;
    if (this.start) {
      setTimeout(() => {
          this.closed = true;
      }, 2000);
    } else {
      this.closed = false;
    }
  }

}




