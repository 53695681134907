import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { GlobalVars } from '../../app/app.vars';

import { Admin3b } from '../admin3b/admin3b';
import { Admin3d } from '../admin3d/admin3d';

@Component({
  selector: 'page-admin3c',
  templateUrl: 'admin3c.html'
})
export class Admin3c {
  
  gauge: any;
  pressureLimits: any;
  altitudeRange: any;
  idealPressure: any;
  
  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController, public globalVars: GlobalVars) {
  }

  ionViewDidEnter() {
    console.log('admin 3c');

    this.gauge = this.getRandom(9.0, 13.0);

    this.addLabel();
  
    let toast = this.toastCtrl.create({
      message: "Inflation is complete when final readout pressure on the digital gauge is stable and reads between 9.0 and 13.0 kPa.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);
  }

  getRandom(min, max) {
    return (Math.random() * (max - min) + min).toFixed(1);
  }

  addLabel() {
    let obj = this.globalVars.getGlobalVar();

    this.pressureLimits = 'Pressure Limits: ' + obj.pressureLimits + ' kPa';
    this.altitudeRange  = 'Altitude Range: ' + obj.altitudeRange + ' ft';
    this.idealPressure  = 'Ideal Pressure: ' + obj.idealPressure + ' kPa';
  }

  prevPage = Admin3b;
  nextPage = Admin3d;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
    let alert = this.alertCtrl.create({
      message: 'IF THE GAUGE DOES NOT STABILIZE, the balloon inflation process is not complete or the balloon system may have a leak. Monitor pressure for additional 30 seconds. If pressure is stable (does not drop more than 0.3 kPa) and reads between 9.0 and 13.0 kPa, proceed to the next step. If the pressure continues to decrease, contact Obalon Customer Service. The Balloon may require endoscopic removal.',
      buttons: [{
      text: 'Ok',
      handler: () => {
        // user has clicked the alert button
        // begin the alert's dismiss transition
        let navTransition = alert.dismiss();

        navTransition.then(() => {
          this.navCtrl.setRoot(page);
        });
  
        return false;
      }
    }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}