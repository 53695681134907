import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Step3a } from '../step3a/step3a';
import { Admin1 } from '../admin1/admin1';

@Component({
  selector: 'page-admin',
  templateUrl: 'admin.html'
})
export class Admin {

  constructor(public navCtrl: NavController) {
  }

  ionViewDidLoad() {
    console.log('admin');
  }

  prevPage = Step3a;
  nextPage = Admin1;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
