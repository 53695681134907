import { NgModule } from '@angular/core';
import { IonicApp, IonicModule } from 'ionic-angular';
import { MyApp } from './app.component';
import { Splash } from '../pages/splash/splash';
import { Index } from '../pages/index/index';
import { Intro } from '../pages/intro/intro';

import { Equipment } from '../pages/equipment/equipment';
  import { Details1 } from '../pages/details1/details1';
    import { Popover1 } from '../pages/popover1/popover1';
  import { Details2 } from '../pages/details2/details2';
  import { Details3 } from '../pages/details3/details3';
  import { Details4 } from '../pages/details4/details4';
  import { Details5 } from '../pages/details5/details5';
  import { Details6 } from '../pages/details6/details6';
  import { Details7 } from '../pages/details7/details7';

// $ ionic g page MyPage1

import { GlobalVars } from './app.vars';
// add to providers below too

import { Step1a } from '../pages/step1a/step1a';
import { Step1b } from '../pages/step1b/step1b';
import { Step1c } from '../pages/step1c/step1c';
import { Step1d } from '../pages/step1d/step1d';

import { Step2a } from '../pages/step2a/step2a';
import { Step2b } from '../pages/step2b/step2b';
  import { Step2b1 } from '../pages/step2b1/step2b1';
  import { Step2b2 } from '../pages/step2b2/step2b2';
  import { Step2b3 } from '../pages/step2b3/step2b3';

import { Step2c } from '../pages/step2c/step2c';
import { Step2d } from '../pages/step2d/step2d';

import { Step3a } from '../pages/step3a/step3a';

import { Admin } from '../pages/admin/admin';
import { Admin1 } from '../pages/admin1/admin1';
import { Admin1a } from '../pages/admin1a/admin1a';
import { Admin1b } from '../pages/admin1b/admin1b';

import { Admin2a } from '../pages/admin2a/admin2a';
import { Admin2a1 } from '../pages/admin2a1/admin2a1';
import { Admin2b } from '../pages/admin2b/admin2b';
import { Admin2c } from '../pages/admin2c/admin2c';

import { Admin3a } from '../pages/admin3a/admin3a';
import { Admin3b } from '../pages/admin3b/admin3b';
import { Admin3c } from '../pages/admin3c/admin3c';
import { Admin3d } from '../pages/admin3d/admin3d';
import { Admin3e } from '../pages/admin3e/admin3e';

import { Admin4a } from '../pages/admin4a/admin4a';
import { Admin4b } from '../pages/admin4b/admin4b';
import { Admin4c } from '../pages/admin4c/admin4c';
import { Admin4d } from '../pages/admin4d/admin4d';
import { Admin4e } from '../pages/admin4e/admin4e';

import { Admin5a } from '../pages/admin5a/admin5a';

@NgModule({
  declarations: [
    MyApp,
    Splash,
    Index,
    Intro,
    Equipment,
      Details1,
        Popover1,
      Details2,
      Details3,
      Details4,
      Details5,
      Details6,
      Details7,
    Step1a,
    Step1b,
    Step1c,
    Step1d,
    Step2a,
    Step2b,
      Step2b1,
      Step2b2,
      Step2b3,
    Step2c,
    Step2d,
    Step3a,
    Admin,
    Admin1,
    Admin1a,
    Admin1b,
    Admin2a,
    Admin2a1,
    Admin2b,
    Admin2c,
    Admin3a,
    Admin3b,
    Admin3c,
    Admin3d,
    Admin3e,
    Admin4a,
    Admin4b,
    Admin4c,
    Admin4d,
    Admin4e,
    Admin5a
  ],
  imports: [
    IonicModule.forRoot(MyApp)
  ],
  bootstrap: [IonicApp],
  entryComponents: [
    MyApp,
    Splash,
    Index,
    Intro,
    Equipment,
      Details1,
        Popover1,
      Details2,
      Details3,
      Details4,
      Details5,
      Details6,
      Details7,
    Step1a,
    Step1b,
    Step1c,
    Step1d,
    Step2a,
    Step2b,
      Step2b1,
      Step2b2,
      Step2b3,
    Step2c,
    Step2d,
    Step3a,
    Admin,
    Admin1,
    Admin1a,
    Admin1b,
    Admin2a,
    Admin2a1,
    Admin2b,
    Admin2c,
    Admin3a,
    Admin3b,
    Admin3c,
    Admin3d,
    Admin3e,
    Admin4a,
    Admin4b,
    Admin4c,
    Admin4d,
    Admin4e,
    Admin5a
  ],
  providers: [ GlobalVars ]
})
export class AppModule {}
