import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin4b } from '../admin4b/admin4b';
import { Admin4d } from '../admin4d/admin4d';

@Component({
  selector: 'page-admin4c',
  templateUrl: 'admin4c.html'
})
export class Admin4c {
  
  start = false;
  startb = false;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 4c');

    let toast = this.toastCtrl.create({
      message: "Rapidly push the syringe in one quick and deliberate motion to eject the Catheter. If the Catheter has not ejected, you will need to use the second syringe to try again.  Prior to removing the first syringe, rotate the stopcock valve to open the flow of gas. Then attach the second syringe and rotate the stopcock back to the closed position.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    //  this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }


  toggleOK() {
    this.start = !this.start;
    this.startb = false;
  }

  prevPage = Admin4b;
  nextPage = Admin4d;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}