import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin3a } from '../admin3a/admin3a';
import { Admin3c } from '../admin3c/admin3c';

@Component({
  selector: 'page-admin3b',
  templateUrl: 'admin3b.html'
})
export class Admin3b {
  
  start = false;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  pausePlay(event) {
    let video = event.target;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  ionViewDidEnter() {
    console.log('admin 3b');
  
    let toast = this.toastCtrl.create({
      message: "Wait a minimum of 2 minutes from when the Green Valve is opened. Inflation is complete when final readout pressure on the digital gauge is stable and reads between 9.0 and 13.0 kPa.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    });

    setTimeout(() => {
    //  toast.present();
    }, 2000);

    setTimeout(() => {
    //  this.start = true;
    }, 1000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Admin3a;
  nextPage = Admin3c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}