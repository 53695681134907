import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin3d } from '../admin3d/admin3d';
import { Admin4a } from '../admin4a/admin4a';

@Component({
  selector: 'page-admin3e',
  templateUrl: 'admin3e.html'
})
export class Admin3e {
  
  start = false;
  
  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 3e');

    let toast = this.toastCtrl.create({
      message: "Close the green valve on EzFill Dispenser by rotating the valve clockwise. \n\nPerform a second 30-second stability check to verify that the digital gauge is stable.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    //  this.start = true;
    });

    setTimeout(() => {
     // toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 3000);
  }

  prevPage = Admin3d;
  nextPage = Admin4a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}