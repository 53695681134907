import { Component } from '@angular/core';
import { NavController, ViewController, PopoverController } from 'ionic-angular';

import { Popover1 } from '../popover1/popover1';

@Component({
  selector: 'page-details6',
  templateUrl: 'details6.html'
})
export class Details6 {

  constructor(public navCtrl: NavController, public viewCtrl: ViewController, public popoverCtrl: PopoverController) {}

  ionViewDidLoad() {
  }

  popover1 = Popover1;

  showPopover(event, page, data) {
    let popover = this.popoverCtrl.create(page, data);
    popover.present({
      ev: event
    });
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }
}
