import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin2a1 } from '../admin2a1/admin2a1';
import { Admin2c } from '../admin2c/admin2c';

import { GlobalVars } from '../../app/app.vars';

@Component({
  selector: 'page-admin2b',
  templateUrl: 'admin2b.html'
})
export class Admin2b {

  start = false;
  gauge: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController, public globalVars: GlobalVars) {
  }

  ionViewDidEnter() {
    console.log('admin 2b');

    let obj = this.globalVars.getGlobalVar();

    this.gauge = obj.idealPressure;

    let toast = this.toastCtrl.create({
      message: "AFTER x-ray, connect the EzFill Dispenser via the Extension Tube to the Catheter. It is important to ensure it is connected tightly.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }
  
  prevPage = Admin2a1;
  nextPage = Admin2c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}