import { Component } from '@angular/core';
import { NavController, ToastController} from 'ionic-angular';

import { Admin3c } from '../admin3c/admin3c';
import { Admin3e } from '../admin3e/admin3e';

@Component({
  selector: 'page-admin3d',
  templateUrl: 'admin3d.html'
})
export class Admin3d {
  
  gauge: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 3d');

    this.gauge = this.getRandom(9.0, 13.0);

    let toast = this.toastCtrl.create({
      message: "Monitor the gauge for at least 30 seconds to assess balloon pressure stability. The balloon pressure is stable if it does not steadily decrease by more than 0.3 kPa within a 30 second period.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);
  }

  getRandom(min, max) {
    return (Math.random() * (max - min) + min).toFixed(1);
  }

  prevPage = Admin3c;
  nextPage = Admin3e;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}