import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { GlobalVars } from '../../app/app.vars';

import { Step2b2 } from '../step2b2/step2b2';
import { Step2d } from '../step2d/step2d';

@Component({
  selector: 'page-step2c',
  templateUrl: 'step2c.html'
})
export class Step2c {

  gauge: any;
  pressureLimits: any;
  altitudeRange: any;
  idealPressure: any;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController, public globalVars: GlobalVars) {
  }

  ionViewDidEnter() {
    console.log('2c');

    let obj = this.globalVars.getGlobalVar();
    this.gauge = obj.idealPressure; // this.getRandom(obj.pressureMin, obj.pressureMax);
    this.addLabel();
  }

  getRandom(min, max) {
    return (Math.random() * (max - min) + min).toFixed(1);
  }

  addLabel() {
    let obj = this.globalVars.getGlobalVar();

    this.pressureLimits = 'Pressure: ' + obj.pressureLimits + ' kPa';
    this.altitudeRange  = 'Operating Altitude ' + obj.altitudeRange + ' ft';
    this.idealPressure  = 'City, State: Confirm Your Location';
  }

  prevPage = Step2b2;
  nextPage = Step2d;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

}