import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step1a } from '../step1a/step1a';
import { Step1c } from '../step1c/step1c';

@Component({
  selector: 'page-step1b',
  templateUrl: 'step1b.html'
})
export class Step1b {
  
  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {

  console.log('1b');

    this.toast = this.toastCtrl.create({
      message: "Remove the cover from the end of the dispenser (keep for later use).",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  ionViewWillLeave() {
  }

  prevPage = Step1a ;
  nextPage = Step1c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}