import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { Step2c } from '../step2c/step2c';
import { Step3a } from '../step3a/step3a';

@Component({
  selector: 'page-step2d',
  templateUrl: 'step2d.html'
})
export class Step2d {
  
  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }
  
  ionViewDidEnter() {
  console.log('2d');

    let alert = this.alertCtrl.create({
      cssClass: 'no-icon',
      message: 'Prior to patient administration, the green valve on the EzFill Dispenser must remain in the closed position. Leaving the green valve open will compromise the Balloon Administration Procedure. Do not proceed with the Balloon Administration Procedure if the green valve is opened. Follow the Obalon Inflation System Operational Instructions in its entirety prior to Balloon Administration. Do not proceed with Balloon Inflation if any of the following occur: The dispenser is not within pressure range or is not stable (within 0.3kPa for 30seconds) The Green Valve is not in the "Closed Position".',
      buttons: [{
      text: 'Ok',
      handler: () => {
        setTimeout(() => {
        //  this.toast.present();
        }, 1000);

        setTimeout(() => {
        //  this.start = true;
        }, 3000);

        setTimeout(() => {
        //  this.toast.data.message = this.toast.data.message + "\n\nThe valve is shown here in the closed position."
        }, 4000);
      }
    }],
      enableBackdropDismiss: false
    });

    this.toast = this.toastCtrl.create({
      message: "Close the green valve on EzFill Dispenser by rotating the valve clockwise. \n\nTHIS IS AN EXTREMELY IMPORTANT STEP",
      position: 'middle',
      dismissOnPageChange: true
    });

    setTimeout(() => {
    //  alert.present();
    }, 1000);

    this.toast.onDidDismiss(() => {
    //  this.start = true;
    });
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Step2c;
  nextPage = Step3a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
    let alert = this.alertCtrl.create({
      message: 'Re-verify that the Digital Gauge pressure remains stable. The dispenser pressure is stable if it does not decrease by more than 0.3 kPa within a 30 second period.',
      buttons: [{
      text: 'Ok',
      handler: () => {
        // user has clicked the alert button
        // begin the alert's dismiss transition
        let navTransition = alert.dismiss();

        navTransition.then(() => {
          this.navCtrl.setRoot(page);
        });
  
        return false;
      }
    }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}