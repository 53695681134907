import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Admin2a } from '../admin2a/admin2a';
import { Admin2b } from '../admin2b/admin2b';

@Component({
  selector: 'page-admin2a1',
  templateUrl: 'admin2a1.html'
})
export class Admin2a1 {

  constructor(public navCtrl: NavController) {}

  ionViewDidLoad() {
    console.log('Admin2a1');
  }

   prevPage = Admin2a;
  nextPage = Admin2b;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
