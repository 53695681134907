import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Admin } from '../admin/admin';
import { Admin1a } from '../admin1a/admin1a';

@Component({
  selector: 'page-admin1',
  templateUrl: 'admin1.html'
})
export class Admin1 {

  constructor(public navCtrl: NavController) {}

  ionViewDidLoad() {
    console.log('admin1');
  }

  pausePlay(event) {
    let video = event.target;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  prevPage = Admin;
  nextPage = Admin1a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

}
