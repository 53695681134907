import { Component } from '@angular/core';

import { NavController, NavParams, AlertController } from 'ionic-angular';

import { Intro } from '../intro/intro';
import { Equipment } from '../equipment/equipment';

import { Step1a } from '../step1a/step1a';
import { Step2a } from '../step2a/step2a';
import { Step3a } from '../step3a/step3a';

import { Admin } from '../admin/admin';
import { Admin1a } from '../admin1a/admin1a';
import { Admin2a } from '../admin2a/admin2a';
import { Admin2b } from '../admin2b/admin2b';
import { Admin4a } from '../admin4a/admin4a';

@Component({
  selector: 'page-index',
  templateUrl: 'index.html'
})
export class Index {

  constructor(public navCtrl: NavController, public navParams: NavParams, private alertCtrl: AlertController) {
  }

  ionViewDidEnter() {
    console.log('index');

    let alert = this.alertCtrl.create({
      message: "You must read the Obalon Balloon System Instructions for Use in its entirety for Balloon Administrations, Use and Removals. This Training Simulator is not intended to replace the Instructions for Use but is to be used as supplementary information to prepare the Inflation System. This document is not intended for use as a standalone document.",
      buttons: [{
      text: 'Ok',
      handler: () => {
        let navTransition = alert.dismiss();
        
        return false;
      }
    }],
      enableBackdropDismiss: false
    });

    alert.present();
  }

  introPage = Intro;
  equipmentPage = Equipment;

  step1a = Step1a;
  step2a = Step2a;
  step3a = Step3a;

  admin = Admin;
  admin1a = Admin1a;
  admin2a = Admin2a;
  admin3a = Admin2b;
  admin4a = Admin4a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}