import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin3e } from '../admin3e/admin3e';
import { Admin4b } from '../admin4b/admin4b';

@Component({
  selector: 'page-admin4a',
  templateUrl: 'admin4a.html'
})
export class Admin4a {
  
  start = false;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 4a');

    let toast = this.toastCtrl.create({
      message: "Attach the pre-filled syringe to the valve with the stopcock valve in the open position.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Admin3e;
  nextPage = Admin4b;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}