import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step2b1 } from '../step2b1/step2b1';
import { Step2c } from '../step2c/step2c';

@Component({
  selector: 'page-step2b2',
  templateUrl: 'step2b2.html'
})
export class Step2b2 {

  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
  console.log('2b2');
  
    this.toast = this.toastCtrl.create({
      message: "Insert the EzFill Can into the dispenser and close the lever on the dispenser.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present()
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Step2b1 ;
  nextPage = Step2c;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
