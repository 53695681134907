import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { Admin4c } from '../admin4c/admin4c';
import { Admin4e } from '../admin4e/admin4e';

@Component({
  selector: 'page-admin4d',
  templateUrl: 'admin4d.html'
})
export class Admin4d {
  
  start = false;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 4d');

    let toast = this.toastCtrl.create({
      message: "Slowly draw catheter out of patient’s mouth. Inspect the Catheter, including the needle and needle sleeve to ensure it is intact.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Admin4c;
  nextPage = Admin4e;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
    let alert = this.alertCtrl.create({
      cssClass: 'no-icon',
      message: 'Visually inspect the catheter and visualize the needle inside the needle sleeve (which is the white protective hub that came attached to the capsule device) to ensure the needle is intact. If the needle is not inside the needle sleeve, remove the balloon endoscopically.',
      buttons: [{
      text: 'Ok',
      handler: () => {
        // user has clicked the alert button
        // begin the alert's dismiss transition
        let navTransition = alert.dismiss();

        navTransition.then(() => {
          this.navCtrl.setRoot(page);
        });
  
        return false;
      }
    }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}