import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin2c } from '../admin2c/admin2c';
import { Admin3b } from '../admin3b/admin3b';

@Component({
  selector: 'page-admin3a',
  templateUrl: 'admin3a.html'
})
export class Admin3a {
  
  start = false;
  loaded = false;
  
  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 3a');

    let toast = this.toastCtrl.create({
      message: "Open the Green Valve on the Obalon EzFill Dispenser by turning it counter clockwise to fill the balloon.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    //  this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
      this.loaded = true;
    }, 1000);

    setTimeout(() => {
     // this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }

  prevPage = Admin2c;
  nextPage = Admin3b;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}