import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Splash } from '../splash/splash';
import { Admin4e } from '../admin4e/admin4e';

@Component({
  selector: 'page-admin5a',
  templateUrl: 'admin5a.html'
})
export class Admin5a {

  constructor(public navCtrl: NavController) {}

  ionViewDidEnter() {
    console.log('admin 5a');
  }

  splashPage = Splash;
  prevPage = Admin4e;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
