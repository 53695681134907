import { Component } from '@angular/core';
import { NavController, ToastController, AlertController } from 'ionic-angular';

import { Step1d } from '../step1d/step1d';
import { Step2b1 } from '../step2b1/step2b1';

@Component({
  selector: 'page-step2b',
  templateUrl: 'step2b.html'
})
export class Step2b {
  
  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
  console.log('2b');

    let toast = this.toastCtrl.create({
      message: "Press the power button on the dispenser’s digital gauge, and verify that the displayed value is in kPa and reads '0.0'.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
      toast.present();
    }, 1000);

    setTimeout(() => {
      this.start = true;
      toast.data.message = toast.data.message + "\n\nIf not '0.0' then hold the zero button for at least 2 seconds. Note: Do not press zero button unless the green valve is in the open position.";
    }, 3000);
  }

  prevPage = Step1d ;
  nextPage = Step2b1;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
    let alert = this.alertCtrl.create({
      message: 'Do not proceed with EzFill Dispenser setup until digital gauge read 0.0 kPa.',
      buttons: [{
      text: 'Ok',
      handler: () => {
        // user has clicked the alert button
        // begin the alert's dismiss transition
        let navTransition = alert.dismiss();

        navTransition.then(() => {
          this.navCtrl.setRoot(page);
        });
  
        return false;
      }
    }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}