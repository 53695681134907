import { Component, ViewChild } from '@angular/core';
import { Nav, Platform } from 'ionic-angular';
import { StatusBar, Splashscreen } from 'ionic-native';

import { Splash } from '../pages/splash/splash';
import { Index } from '../pages/index/index';
import { Intro } from '../pages/intro/intro';
import { Equipment } from '../pages/equipment/equipment';

import { Step1a } from '../pages/step1a/step1a';
import { Step2a } from '../pages/step2a/step2a';
import { Step3a } from '../pages/step3a/step3a';

import { Admin } from '../pages/admin/admin';
import { Admin1a } from '../pages/admin1a/admin1a';
import { Admin2a } from '../pages/admin2a/admin2a';
import { Admin2b } from '../pages/admin2b/admin2b';
import { Admin4a } from '../pages/admin4a/admin4a';

@Component({
  templateUrl: 'app.html'
})
export class MyApp {
  @ViewChild(Nav) nav: Nav;

  rootPage: any = Splash;

  startPage = { title: '', component: Splash };

  group0: Array<{title: string, component: any}>;
  group1: Array<{title: string, component: any}>;
  group2: Array<{title: string, component: any}>;

  constructor(public platform: Platform) {
    this.initializeApp();

    // used for an example of ngFor and navigation
    this.group0 = [
      { title: 'Altitude Calculator', component: Splash },
      { title: 'Chapters', component: Index },
      { title: 'Component List', component: Equipment }
    ];
    this.group1 = [
      { title: 'Step 1: Prepare the EzFill Dispenser', component: Step1a },
      { title: 'Step 2: Prepare the EzFill Can', component: Step2a },
      { title: 'Step 3: Prepare the Ballon Contents', component: Step3a }
    ];
    this.group2 = [
      { title: 'Pre Balloon Placement Checklist', component: Admin },
      { title: 'Step 1: Capsule Swallow', component: Admin1a },
      { title: 'Step 2: Balloon Placement Detection', component: Admin2a },
      { title: 'Step 3: Performing Balloon Inflations', component: Admin2b },
      { title: 'Step 4: Retrieving the Catheter', component: Admin4a }
    ];
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      StatusBar.styleDefault();
      Splashscreen.hide();
    });
  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    this.nav.setRoot(page.component);
  }
}
