import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Admin1a } from '../admin1a/admin1a';
import { Admin2a } from '../admin2a/admin2a';

@Component({
  selector: 'page-admin1b',
  templateUrl: 'admin1b.html'
})
export class Admin1b {

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 1b');

    let toast = this.toastCtrl.create({
      message: "Activate Capsule/Catheter coating by quickly dipping the Capsule/Catheter into the bowl of water.  AVOID submerging the end of the Catheter to keep water from enter the Catheter.  \n\nAfter wetting the capsule and catheter, immediately instruct the patient to swallow the capsule.  If patient has not swallowed the capsule within 90 seconds, please discard capsule and catheter.  Provide patient with a new capsule and try again.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
    });

    setTimeout(() => {
    //  toast.present()
    }, 1000);
  }

  prevPage = Admin1a;
  nextPage = Admin2a;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
