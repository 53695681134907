import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { Admin1b } from '../admin1b/admin1b';
import { Admin2a1 } from '../admin2a1/admin2a1';

@Component({
  selector: 'page-admin2a',
  templateUrl: 'admin2a.html'
})
export class Admin2a {
  
  start = false;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController) {
  }

  ionViewDidEnter() {
    console.log('admin 2a');

    let toast = this.toastCtrl.create({
      message: "After the patient has swallowed the capsule, use fluoroscopy/x-ray to verify that the Capsule is in the stomach.",
      position: 'middle',
      dismissOnPageChange: true
    });

    toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  toast.present();
    }, 1000);

    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }



  prevPage = Admin1b;
  nextPage = Admin2a1;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
    let alert = this.alertCtrl.create({
      message: 'Prior to inflation it is important to verify the capsule has passed the GE junction and entered the stomach.',
      buttons: [{
      text: 'Ok',
      handler: () => {
        // user has clicked the alert button
        // begin the alert's dismiss transition
        let navTransition = alert.dismiss();

        navTransition.then(() => {
          this.navCtrl.setRoot(page);
        });
  
        return false;
      }
    }],
      enableBackdropDismiss: false
    });
    alert.present();
  }
}