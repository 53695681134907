import { Component } from '@angular/core';
import { NavController, AlertController, ToastController } from 'ionic-angular';

import { GlobalVars } from '../../app/app.vars';

import { Equipment } from '../equipment/equipment';
import { Step1b } from '../step1b/step1b';

@Component({
  selector: 'page-step1a',
  templateUrl: 'step1a.html'
})
export class Step1a {
  
  start = false;
  toast: any;
  pressureLimits: any;
  altitudeRange: any;
  idealPressure: any;

  constructor(public navCtrl: NavController, private alertCtrl: AlertController, private toastCtrl: ToastController, public globalVars: GlobalVars) {
  }

  ionViewDidEnter() {
    console.log('1a');

    let alert = this.alertCtrl.create({
      message: "CAUTION: DO NOT USE the EzFill System at an elevation outside of the prescribed Operating Altitude Range. Doing so could over- or under-inflate a Balloon and can lead to an increased risk of Balloon deflation.",
      buttons: [{
      text: 'Ok',
      handler: () => {
        let navTransition = alert.dismiss();
        
        return false;
      }
    }],
      enableBackdropDismiss: false
    });

    alert.present();

    this.toast = this.toastCtrl.create({
      message: "Check to see if the dispenser is correctly set for your location’s corresponding altitude according to the label.",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
      this.addLabel();
    });

    setTimeout(() => {
//      this.toast.present();

/*
      document.querySelector('div.toast-message').addEventListener('click', function(event) {
        event.target.classList.add('clicked')

        this.start = true;
        this.addLabel();
      }.bind(this), false);
*/

    }, 2000);

    setTimeout(() => {
    //  this.start = true;
    //  this.addLabel();
    }, 3000);

  }

  addLabel() {
    setTimeout(() => {
      let obj = this.globalVars.getGlobalVar();

      this.pressureLimits = 'Pressure: ' + obj.pressureLimits + ' kPa';
      this.altitudeRange  = 'Operating Altitude: ' + obj.altitudeRange + ' ft';
      this.idealPressure  = 'City, State: Confirm Your Location';
    }, 3000);
  }

  ionViewWillLeave() {
  }

  toggleOK() {
    this.start = !this.start;

    if (this.start) {
      this.addLabel();
    } else {
      this.pressureLimits = '';
      this.altitudeRange  = '';
      this.idealPressure  = '';
    }
  }

  prevPage = Equipment;
  nextPage = Step1b;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }

  showAlertThenOpenPage(page) {
  	let alert = this.alertCtrl.create({
      message: "CAUTION: DO NOT USE the EzFill System at an elevation outside of the labeled Operating Altitude Range. Doing so could over- or under-inflate a Balloon and can lead to an increased risk of balloon deflation.",
      buttons: [{
	    text: 'Ok',
	    handler: () => {
	      // user has clicked the alert button
	      // begin the alert's dismiss transition
	      let navTransition = alert.dismiss();

	      navTransition.then(() => {
          //this.toast.dismiss();
	        this.navCtrl.setRoot(page);
	      });
	
	      return false;
	    }
	  }],
      enableBackdropDismiss: false
    });

    this.toast.dismiss();
    alert.present();
  }
}