import { Component } from '@angular/core';
import { NavController } from 'ionic-angular';

import { Index } from '../index/index';
import { Equipment } from '../equipment/equipment';

@Component({
  selector: 'page-intro',
  templateUrl: 'intro.html'
})
export class Intro {

  constructor(public navCtrl: NavController) {}

  ionViewDidLoad() {
  }

  prevPage = Index;
  nextPage = Equipment;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
