import {Injectable} from '@angular/core';

@Injectable()
export class GlobalVars {
  data: any;

  constructor() {
    this.data = {};
  }

  setGlobalVar(obj) {
    this.data = obj;
  }

  getGlobalVar() {
    return this.data;
  }
}