import { Component } from '@angular/core';
import { NavController, ModalController } from 'ionic-angular';

import { Splash } from '../splash/splash';
import { Step1a } from '../step1a/step1a';
import { Details1 } from '../details1/details1';
import { Details2 } from '../details2/details2';
import { Details3 } from '../details3/details3';
import { Details4 } from '../details4/details4';
import { Details5 } from '../details5/details5';
import { Details6 } from '../details6/details6';
import { Details7 } from '../details7/details7';

@Component({
  selector: 'page-equipment',
  templateUrl: 'equipment.html'
})
export class Equipment {

  constructor(public navCtrl: NavController, public modalCtrl: ModalController) {}

  ionViewDidLoad() {
  }

  prevPage = Splash;
  nextPage = Step1a;
  details1 = Details1;
  details2 = Details2;
  details3 = Details3;
  details4 = Details4;
  details5 = Details5;
  details6 = Details6;
  details7 = Details7;

  openModal(page, params) {
    let modal = this.modalCtrl.create(page, params, {enableBackdropDismiss: true});
    modal.present();
  }

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}
