import { Component } from '@angular/core';
import { NavController, ToastController } from 'ionic-angular';

import { Step1b } from '../step1b/step1b';
import { Step1d } from '../step1d/step1d';

@Component({
  selector: 'page-step1c',
  templateUrl: 'step1c.html'
})
export class Step1c {
  
  start = false;
  toast: any;

  constructor(public navCtrl: NavController, private toastCtrl: ToastController) {
  }

  ionViewDidLoad() {
    console.log('1c');
  }

  ionViewDidEnter() {
    this.toast = this.toastCtrl.create({
      message: "Attach the proximal end of the extension tube to the EzFill dispenser.  Ensure that the connection is tight and that the stopcock valve is in the “Closed” position",
      position: 'middle',
      dismissOnPageChange: true
    });

    this.toast.onDidDismiss(() => {
      this.start = true;
    });

    setTimeout(() => {
    //  this.toast.present()
    }, 1000);
    
    setTimeout(() => {
    //  this.start = true;
    }, 2000);
  }

  toggleOK() {
    this.start = !this.start;
  }
  
  ionViewWillLeave() {
  }

  prevPage = Step1b ;
  nextPage = Step1d;

  openPage(page) {
    this.navCtrl.setRoot(page);
  }
}